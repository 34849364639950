import * as React from "react"
import BannerSection from "../components/404/Banner"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Header from "../components/header"

const NotFoundPage = () => (
  <section className="abtus">
    <Layout>
      <Helmet>
        <title>404 | Page Not Found</title>
      </Helmet>
      <Header />
      <BannerSection />
    </Layout>
  </section >
)

export default NotFoundPage
