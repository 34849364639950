import React from 'react'




class BannerSection extends React.Component {

  render() {

    return (
      <section className="banner notfound">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side">
              <h1 className="main-heading">Page Not Found</h1>
              <p className="pharagraph">Your search has found a page that's not responding. We'll have this patched up as soon as possible. So please go back to the home page.</p>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <img width="658px" height="360px" src="https://coinsclone.mo.cloudinary.net/images/404.svg" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection